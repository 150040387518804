import { css } from 'emotion';
import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import shop from '../images/shop.png'
import moter from '../images/moter.png'
// interface IPlaces {
//   text: string,
//   lat: number,
//   lng: number
// }

function mapOptionsCreator() {
  return {
    scrollwheel: false,
    zoomControl: false
  };
}
const MarKer = ({ key, position, text, lat, lng }: { key: string, position:number, text: string, lat: number, lng: number }) => <div>
  <div className={styles.marker}>
    <div className="wrap-maker">
      {text === 's' ? <img className={styles.iconPng} src={shop} alt={'shop'} /> : text === 'c' ? (<><i className="icon-maker maker" /> <i className="icon-user user-maker" /></>) : text === 'l' ? <img className={styles.moter} src={moter} alt={'moter'}/> :<span className={styles.spandot}/> }
      {/* <i className="icon-user user-maker" /> */}
      <span>{position}</span>
    </div>
  </div>
</div >;



const MainMap = ({ route, zoom }: { route: Array<{ text: string, lat: number, lng: number }>, zoom: number }) => {

  // console.log(route)

  const [center, setCenter] = useState({ lat: 13.736717, lng: 100.523186 })
  // const [places] = useState<IPlaces[]>([{ text: "x", lat: 13.736717, lng: 100.523186 }])
  const [isLoading, setload] = useState(false)

  const loadMarkers = () => {
    let renderedMarkers = []
    for (let index = 0; index < route.length; index++) {
      const element: any = route[index];
      // if(index===0){
      //   setCenter({ lat: element.lat, lng: element.lng })
      // }
      renderedMarkers.push(<MarKer
        key={`${index}-s`}
        position={index}
        text={((route.length-1) === index )&& (index !== 1) && element.text !== 'c' ? 'l'  : element.text}
        lat={element.lat}
        lng={element.lng}
      ></MarKer>)
    }
    return renderedMarkers;
  }


  useEffect(() => {

    // loadMarkers()

    setCenter({ lat: route[0].lat, lng: route[0].lng })
    // console.log('useEffect', isLoading, places)
    setload(true)

  }, [isLoading])

  // const mrk = ;

  return (
    // Important! Always set the container height explicitly
    <div className={styles.root} >
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAiTmoMnf8Qsut2ePCZwmKQVSSF-aNWel4' }}
        defaultCenter={center}
        defaultZoom={zoom}
        zoom={zoom}
        options={mapOptionsCreator}
      >
        {isLoading ? loadMarkers() : null}
      </GoogleMapReact>
    </div>
  );
}

MainMap.defaultProps = {
  route: [],
  zoom: 12
}

const styles = {
  root: css`
  height: 100%;
  width: 100%;
  `,
  marker: css`
  position: absolute;
  color: rgb(0, 0, 0);
  z-index: 990;
  left: -40px;
  width: 100px;
  text-align: left;
  `,
  iconPng: css`
    width: 44px;
  `,
  moter: css`
    height: 44px;
  `,
  spandot: css`
    height: 20px;
    width: 20px;
    float: left;
    background-color: #FDB513;
    border-radius: 50%;
    display: inline-block;
  `,

}

export default MainMap;