


import { css } from 'emotion';
import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import { COLOR } from '../const'
import { getRemark, postRemark } from '../api/githubAPI'

// import { useSelector } from 'react-redux'
// import { hideModal } from '../actions';
// import ReactModal from "react-modal";
// import { useModal } from "react-modal-hook";


// interface ReactPortal extends ReactElement<any> {
//     key: Key | null;
//     children: ReactNode;
// }
const Modal = ({ show, close }: { show: boolean, close: () => void }) => {
  // const show = useSelector((state: any) => state.Modal.show)
  const [inputRemark, setinputRemark] = useState('')

  async function fetchEverything() {
    async function fetchRoutes() {
      const Result: any = await getRemark()
      setinputRemark(Result.text)

    }

    try {
      await Promise.all([fetchRoutes()])
    } catch (err) {
      console.error(err)
    } finally {
      // setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchEverything();
  }, [show]);


  const hendleOk = async () => {
    const Result: any = await postRemark({ text: inputRemark })
    console.log(Result)
    close();
  }
  if (show) {
    return (
      ReactDOM.createPortal(
        <div className={styles.root}>
          <div className={styles.modal}>
            <div className={styles.title}>Remark</div>
            <textarea
              className={styles.inputSearch}
              rows={3}
              onChange={e => setinputRemark(e.target.value)}
            >{inputRemark}</textarea>
            <div>
              <div className={styles.buttonReroute} onClick={() => { hendleOk() }}>Ok</div>
              <div className={styles.buttonClose} onClick={() => { close() }}>Close</div>
            </div>

          </div>
        </div>,
        document.body
      )
    )
  }
  else {
    return null
  }



}

const styles = {
  root: css`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 9999999;
  background: #000000aa;
  `,
  modal: css`
  position: absolute;
  height: 200px;
  width: 360px;
  background: #fff;
  z-index: 9999999;
  margin-left: -180px;
  margin-top: -100px;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  `,
  title: css`
  margin: 18px;
  width: 320px;
  font-size: 18px;
  `,
  inputSearch: css`
   resize: none;
   margin: 18px;
   width: 320px;
    display: block;
    padding: 5px 6px;
    font-size: 14px;
    font-weight: 400;
    line-height:14px;
    color: ${COLOR['DARK_2']};
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid ${COLOR['BLUE_DARK_OPA_1']};
    border-radius: 7px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
    &:focus{
      box-shadow: none;
      outline: none;
    }
    &::-webkit-input-placeholder{
      color: ${COLOR['PLACEHOLDER']};
    }
    &:-ms-input-placeholder {
      color: ${COLOR['PLACEHOLDER']};
    }
    &::placeholder {
      color: ${COLOR['PLACEHOLDER']};
    }
  `,

  buttonClose: css`
  float: left;
// position: absolute;
  line-height: 40px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  width: 100px;
  height: 40px;
  background: #444966;
  border-radius: 13px;
  bottom: 20px;
  left: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 20px;
`,
  buttonReroute: css`
  float: right;
  // position: absolute;
  line-height: 40px;
  color: #444966;
  font-size: 18px;
  font-weight: bold;
  width: 180px;
  height: 40px;
  background: #FDB513;
  border-radius: 13px;
  bottom: 20px;
  left: 389px;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
`,

};

export default Modal;