// import { action, createAction } from 'typesafe-actions';
import { DateRange } from 'moment-range';
// export const add = (order: object) => action('todos/ADD', { id: cuid(), order, completed: false });
// // add: (title: string) => { type: "todos/ADD"; payload: { id: string, title: string, completed: boolean; }; }

// export const add = createAction('todos/ADD', action => {
//   // Note: "action" callback does not need "type" parameter
//   return (title: string) => action({ id: cuid(), order, completed: false });
// });

export const AUTH_PASS = 'AUTH_PASS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SEARCH = 'SEARCH';
export const FILTER = 'FILTER';
export const COUNT = 'COUNT';
export const ADD_ORDER = 'ADD_ORDER';
export const LOAD_LIST_ORDERS = 'LOAD_LIST_ORDERS';
export const ADD_ROUTE = 'ADD_ROUTE';
export const CREATE_ROUTE = 'CREATE_ROUTE';
export const LOAD_LIST_ROUTE = 'LOAD_LIST_ROUTE';
export const ADD_TODO = 'ADD_TODO';
export const DELETE_TODO = 'DELETE_TODO';



export type Action =
  {
    type: 'AUTH_PASS';
    token: string;
  }
  | {
    type: 'AUTH_FAIL';
  }
  | {
    type: 'SHOW_MODAL';
    index: number;
  }
  | {
    type: 'HIDE_MODAL';
  }
  | {
    type: 'SEARCH';
    keyword: string;
    date: Date;
    sort: number;
    skip: number;
    count: number;
  }
  | {
    type: 'FILTER';
    sender: [string];
    status: [string];
    service:[string];
    box: [string];
    ordertype: [string];
  }
  | {
    type: 'COUNT';
    count: number;
  }
  | {
    type: 'ADD_ORDER';
    order: object;
  }
  | {
    type: 'LOAD_LIST_ORDERS';
    orders: [object];
  }
  | {
    type: 'ADD_ROUTE';
    route: object;
  }
  | {
    type: 'CREATE_ROUTE';
    route: object;
  }
  | {
    type: 'LOAD_LIST_ROUTE';
    routes: [object];
  }
  | {
    type: 'ADD_TODO';
    todo: string;
  }
  | {
    type: 'DELETE_TODO';
    index: number;
  };

  export function auth(token: string) {
    localStorage.setItem('auth', 'true');
    localStorage.setItem('token', token);
    return { type: AUTH_PASS, token: token };
  }

  export function unauth() {
    localStorage.removeItem('auth');
    localStorage.setItem('token', '');
    return { type: AUTH_FAIL };
  }

export function addOrder(order: object) {
  return { type: ADD_ORDER, order: order };
}

export function loadOrders(orders: object[]) {
  return { type: LOAD_LIST_ORDERS, orders: orders };
}

export function addRoute(route: object) {
  return { type: ADD_ROUTE, route: route };
}

export function createRoute(route: object) {
  return { type: CREATE_ROUTE, route: route };
}

export function loadRoutes(routes: object[]) {
  return { type: LOAD_LIST_ROUTE, routes: routes };
}

export function addTodo(todo: string) {
  return { type: ADD_TODO, todo: todo };
}

export function deleteTodo(index: number) {
  return { type: DELETE_TODO, index: index };
}

export function showModal(index: number) {
  return { type: SHOW_MODAL, index: index };
}

export function hideModal() {
  return { type: HIDE_MODAL };
}

export function setSearch(keyword: string, date: Date | DateRange, sort: number, skip: number, count: number) {
  return { type: SEARCH, keyword: keyword, date: date, sort: sort, skip: skip, count: count };
}

export function setFilter(sender: [string], status: [string], service: [string], box: [string], ordertype: [string]) {
  return { type: FILTER, sender: sender, status: status, service: service, box: box, ordertype: ordertype };
}


export function setCount(count: number) {
  return { type: COUNT, count: count };
}