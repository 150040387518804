import { css } from 'emotion';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import GoogleMapReact from 'google-map-react';
import shop from '../images/shop.png'
import moter from '../images/moter.png'
import { COLOR_STARTUS } from '../const'
// import { addRoute } from '../actions';


// interface IPlaces {
//   text: string,
//   lat: number,
//   lng: number
// }



function mapOptionsCreator() {
  return {
    scrollwheel: true,
    zoomControl: true
  };
}
const MarKer = ({ key, r, no, position, text, lat, lng, click }: { key: string, r: boolean, no: string, position: number, text: string, lat: number, lng: number, click: (item: string) => void }) => <div>
  <div className={styles.marker} onClick={() => { click(no) }}>
    <div className="wrap-maker">
      {text === 's' ? <img className={styles.iconPng} src={shop} alt={'shop'} /> : text === 'c' ? (<><i className="icon-maker maker" style={{ color: r ? COLOR_STARTUS['COMPLETED'] : COLOR_STARTUS['ASSIGNING_DRIVER'] }} /> {r ? <i className="user-maker" style={{ fontStyle: 'normal' }}>{position}</i> : <i className="icon-user user-maker" />}</>) : text === 'l' ? <img className={styles.moter} src={moter} alt={'moter'} /> : <span className={styles.spandot} />}
      {/* <i className="icon-user user-maker" /> */}
      {/* <span style={{
        fontSize: '25px',
        left: -'33px',
        top: -'21px',
        position: 'relative'
      }}>{position}</span> */}
    </div>
  </div>
</div >;



const MainMap = ({ route, zoom, clickMarker }: { route: Array<{ text: string, no: string, lat: number, lng: number }>, zoom: number, clickMarker: (item: string) => void }) => {
  // const dispatch = useDispatch();
  const routes: any = useSelector((state: any) => state.Route.routes)

  console.log(route)

  const [center, setCenter] = useState({ lat: 13.736717, lng: 100.523186 })
  // const [places] = useState<IPlaces[]>([{ text: "x", lat: 13.736717, lng: 100.523186 }])
  const [isLoading, setload] = useState(false)

  const handleclick = (item: string) => {
    console.log('handleclick', item)
    clickMarker(item)
    // dispatch(addRoute(route[i]))
  }
  const loadMarkers = () => {
    let renderedMarkers = []
    const orders = routes
    console.log(orders)
    let position = 0
    for (let index = 0; index < route.length; index++) {
      const element: any = route[index];
      let r = false
      for (let i = 0; i < orders.length; i++) {
        const order = orders[i];
        if (order.no === element.no) {
          
          r = true
          position=i+1
          console.log(order.no,position)
          // setCenter({ lat: element.lat, lng: element.lng })
        }

      }
      renderedMarkers.push(<MarKer
        key={`${index}-s`}
        r={r}
        no={element.no}
        click={handleclick}
        position={r ? position : index}
        text={((route.length - 1) === index) && (index !== 1) && element.text !== 'c' ? 'l' : element.text}
        lat={element.lat}
        lng={element.lng}
      ></MarKer>)
    }
    return renderedMarkers;
  }


  useEffect(() => {

    // loadMarkers()
    if (route.length > 0) {
      setCenter({ lat: route[0].lat, lng: route[0].lng })
      // console.log('useEffect', isLoading, places)

    }
    setload(true)


  }, [isLoading])

  // const mrk = ;

  return (
    // Important! Always set the container height explicitly
    <div className={styles.root} >
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAiTmoMnf8Qsut2ePCZwmKQVSSF-aNWel4' }}
        defaultCenter={center}
        defaultZoom={zoom}
        zoom={zoom}
        options={mapOptionsCreator}
      >
        {isLoading ? loadMarkers() : null}
      </GoogleMapReact>
    </div>
  );
}

MainMap.defaultProps = {
  route: [],
  zoom: 12
}

const styles = {
  root: css`
  height: 100%;
  width: 100%;
  `,
  marker: css`
  position: absolute;
  color: rgb(0, 0, 0);
  z-index: 990;
  left: -40px;
  width: 44px;
  cursor: pointer;
  text-align: left;
  `,
  iconPng: css`
    width: 44px;
  `,
  moter: css`
    height: 44px;
  `,
  spandot: css`
    height: 20px;
    width: 20px;
    float: left;
    background-color: #FDB513;
    border-radius: 50%;
    display: inline-block;
  `,

}

export default MainMap;