
import { Action, ADD_ORDER, LOAD_LIST_ORDERS } from '../actions';




export interface IState {
  orders: object[]
}

// interface ServerResponse {
//   data:  object[]
// }

const OrderReducer = (state: IState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ADD_ORDER:
      return {
        ...state,
        orders: [
          ...state.orders,
          {
            order: action.order
          }
        ]
      };
    case LOAD_LIST_ORDERS:
      return {
        ...state,
        orders: action.orders
      };
    default:
      return state;
  };
}




export const INITIAL_STATE: IState = {
  "orders": [{
    "_id": "5dc3fff46cbf4ccfb9ce8d5e",
    "isActive": true,
    "priceTotal": 456,
    "priceVat": 19.62,
    "priceNet": 300,
    "priceShippingNet": 196,
    "priceSpecialDiscount": [],
    "priceDiscount": 40,
    "cancelMessage": null,
    "createAt": "2019-11-07T11:23:59.397Z",
    "updateAt": "2019-11-07T11:28:52.075Z",
    "deliveryAt": {
      "dateDelivery": "2019-11-07T12:35:59.000Z",
      "dateCallDelivery": "2019-11-07T11:35:59.000Z"
    },
    "delivery": {
      "typeCar": "MOTORCYCLE",
      "priceFee": 40,
      "priceDiscount": 0,
      "priceDelivery": 156,
      "logisticsCode": "LLM"
    },
    "paymentStatus": "success",
    "deliveryStatus": "cooking",
    "invoiceTime": {
      "timeConfirm": "2019-11-07T11:26:58.342Z",
      "timePayment": "2019-11-07T11:28:52.075Z"
    },
    "methodPayment": "tranfer",
    "contact": {
      "name": "พะแนง",
      "mobile": "0992226666"
    },
    "address": "17 ซอย ลาดพร้าว 30 จันทรเกษม จตุจักร กรุงเทพมหานคร 10210",
    "location": [
      "13.8037923",
      "100.57580110000004"
    ],
    "addressExtra": "",
    "invoiceNo": "AXC-1911070017",
    "taxInvoiceNo": "AXC-R1911070002",
    "clearingNo": null,
    "taxExtra": null,
    "sentReceipt": null,
    "sentConfirmTime": null,
    "typeOrder": "buynow",
    "chefStatus": "waitcooking",
    "priceFeePayment": 0,
    "channel": "cms",
    "createBy": "5b90ac9f7211880f671b3134",
    "shopInformation": {
      "addressExtra": "1",
      "address": "254 ถนน พญาไท แขวง วังใหม่ เขตปทุมวัน กรุงเทพมหานคร 10330 ประเทศไทย",
      "location": {
        "lat": 13.73749,
        "lng": 100.52891
      },
      "name": "ครัวชลทาน",
      "mobile": "0944464996"
    },
    "additionalDiscount": 0,
    "isBox": true,
    "deliveryOption": "LLM",
    "id": "5dc3fecf430e9124eee5a7be",
    "shopId": "5bdfb2116e90d33f451cb1e6",
    "userId": "5dc3fe85430e9124eee5a7ba",
    "purchaseId": "5dc3ffa5430e9124eee5a7c1",
    "problems": null,
    "deliveryHistory": [],
    "trackingNo": "TH20191107-180001",
    "ref": "fnxxafkcikw011n3au3qy3p",
    "refId": null
  }
  ]

};

export default OrderReducer;