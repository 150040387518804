import React from "react";
import Todo from "./pages/Todo";
import Order from "./pages/Order";
import Route from "./pages/Route";
import Parcel from "./pages/Parcel";
import ReRoute from "./pages/ReRoute";
import PlanRoute from "./pages/PlanRoute";
import Tracking from "./pages/Tracking";
import Login from "./pages/Login";
import Logout from "./pages/Logout";


const routes = {
  "/":() => <Tracking />,
  "/login": () => <Login />,
  "/logout": () => <Logout />,
  "/todo": () => <Todo />,
  "/order": () => <Order />,
  "/route": () => <Route />,
  "/parcel": () => <Parcel />,
  "/reroute":() => <ReRoute />,
  "/planroute":() => <PlanRoute />,
};
export default routes;