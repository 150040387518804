import axios from 'axios';
import { convertDatetiTimestamp } from '../const'
import { DateRange } from 'moment-range';

const host = process.env.NODE_ENV === "production" ? 'https://shipmode.saranros.com' : 'http://122.155.201.108:8015'
// const host = 'http://localhost:3001'

console.log(host)


export interface Orders {
  orders: object
}

export interface ResCancelRoute {
  text: string
}

export interface ResCreatelRoute {
  routeNo: string
}

export interface ResStatusOrder {
  status: object
}


export interface ResLocationOrder {
  locations: [object]
}

export interface ResRemark {
  text: string
}

export async function getRemark() {
  const url = `${host}/api/remarks`
  const { data } = await axios.get(url)
  return data
}

export async function postRemark(body: { text: string }) {
  const url = `${host}/api/remarks`
  const { data } = await axios.post(url, body)
  return data
}

export async function getOptionOrders(date: Date | DateRange) {
  if (date instanceof Date) {
    const timestamp = convertDatetiTimestamp(date);
    const url = `${host}/api/option/orders?date=${timestamp}`
    const { data } = await axios.get(url)
    return data
  } else {
    return { text: "error" }
  }

}

export async function getOptionRoute(date: Date | DateRange) {
  if (date instanceof Date) {
    const timestamp = convertDatetiTimestamp(date);
    const url = `${host}/api/option/route?date=${timestamp}`
    const { data } = await axios.get(url)
    return data
  } else {
    return { text: "error" }
  }
}

export async function getOptionParcel(date: Date | DateRange) {
  if (date instanceof DateRange) {
    const timestamp_s = convertDatetiTimestamp(date.start.toISOString());
    const timestamp_e = convertDatetiTimestamp(date.end.toISOString());
    const url = `${host}/api/option/parcel?s=${timestamp_s}&e=${timestamp_e}`
    const { data } = await axios.get(url)
    return data
  } else {
    return { text: "error" }
  }
}

export async function getOrders(keyword: string, date: Date, sort: number, skip: number, shop: any, packge: any, type: any) {
  const timestamp = convertDatetiTimestamp(date);
  let url = `${host}/api/orders?no=${keyword}&date=${timestamp}&sort=${sort}&skip=${skip}`
  // console.log(shop, service, packge, status)
  let tmp_shop = "";
  for (const iterator of shop) {
    tmp_shop = tmp_shop.concat(`${iterator},`)
  }
  let tmp_type = "";
  for (const iterator of type) {
    tmp_type = tmp_type.concat(`${iterator},`)
  }
  let tmp_packge = "";
  for (const iterator of packge) {
    tmp_packge = tmp_packge.concat(`${iterator},`)
  }


  if (tmp_shop.length > 0) {
    url = url.concat(`&shop=${tmp_shop}`)
  }
  if (tmp_type.length > 0) {
    url = url.concat(`&type=${tmp_type}`)
  }
  if (tmp_packge.length > 0) {
    url = url.concat(`&packge=${tmp_packge}`)
  }
  const { data } = await axios.get<Orders[]>(url)
  return data
}

export async function getRoutes(keyword: string, date: Date, sort: number, skip: number, shop: any, status: any, packge: any, service: any) {
  const timestamp = convertDatetiTimestamp(date);
  let url = `${host}/api/routeinfo?no=${keyword}&date=${timestamp}&sort=${sort}&skip=${skip}`
  let tmp_shop = "";
  for (const iterator of shop) {
    tmp_shop = tmp_shop.concat(`${iterator},`)
  }
  let tmp_service = "";
  for (const iterator of service) {
    tmp_service = tmp_service.concat(`${iterator},`)
  }
  let tmp_status = "";
  for (const iterator of status) {
    tmp_status = tmp_status.concat(`${iterator},`)
  }
  let tmp_packge = "";
  for (const iterator of packge) {
    tmp_packge = tmp_packge.concat(`${iterator},`)
  }


  if (tmp_shop.length > 0) {
    url = url.concat(`&shop=${tmp_shop}`)
  }
  if (tmp_service.length > 0) {
    url = url.concat(`&service=${tmp_service}`)
  }
  if (tmp_status.length > 0) {
    url = url.concat(`&status=${tmp_status}`)
  }
  if (tmp_packge.length > 0) {
    url = url.concat(`&packge=${tmp_packge}`)
  }

  const { data } = await axios.get<Orders[]>(url)
  return data
}

export async function getParcel(keyword: string, date: DateRange, sort: number, skip: number, shop: any, status: any, service: any) {


  // const timestamp = convertDatetiTimestamp(date);
  const timestamp_s = convertDatetiTimestamp(date.start.toISOString());
  const timestamp_e = convertDatetiTimestamp(date.end.toISOString());
  let url = `${host}/api/parcelinfo?no=${keyword}&s=${timestamp_s}&e=${timestamp_e}&sort=${sort}&skip=${skip}`

  // console.log(shop, service, status)
  let tmp_shop = "";
  for (const iterator of shop) {
    tmp_shop = tmp_shop.concat(`${iterator},`)
  }
  let tmp_service = "";
  for (const iterator of service) {
    tmp_service = tmp_service.concat(`${iterator},`)
  }
  let tmp_status = "";
  for (const iterator of status) {
    tmp_status = tmp_status.concat(`${iterator},`)
  }


  if (tmp_shop.length > 0) {
    url = url.concat(`&shop=${tmp_shop}`)
  }
  if (tmp_service.length > 0) {
    url = url.concat(`&service=${tmp_service}`)
  }
  if (tmp_status.length > 0) {
    url = url.concat(`&status=${tmp_status}`)
  }

  // console.log(tmp_shop, url)
  const { data } = await axios.get<Orders[]>(url)
  return data
}

export async function postCreateRoute(body: { ref: string, route: Array<string> }) {
  const url = `${host}/api/reroute`
  const { data } = await axios.post<ResCreatelRoute>(url, body)
  return data
}

export async function completeRoute(routeNo: string) {
  const url = `${host}/api/route/${routeNo}/completed`

  const { data } = await axios.post<ResCancelRoute>(url)
  return data
}

export async function cancelRoute(routeNo: string) {
  const url = `${host}/api/route/${routeNo}`

  const { data } = await axios.delete<ResCancelRoute>(url)
  return data
}

export async function getOrdersWithRef(ref: string, ) {
  const url = `${host}/api/order/ref/${ref}`

  const { data } = await axios.get<Orders[]>(url)
  return data
}

export async function getStatusOrder(trackingNo: string, ) {
  const url = `${host}/api/tracking/${trackingNo}/status`

  const { data, status } = await axios.get<ResStatusOrder>(url)
  if (status == 200) {
    return data.status
  } else {
    return { status: "" }
  }
}


export async function getTracking(trackingNo: string, ) {
  const url = `${host}/api/tracking/${trackingNo}`

  const { data, status } = await axios.get<Orders>(url)
  if (status == 200) {
    return data.orders
  } else {
    return { orders: [] }
  }
}


export async function getLocationOrder(trackingNo: string, ) {
  const url = `${host}/api/tracking/${trackingNo}/location`

  const { data, status } = await axios.get<ResLocationOrder>(url)
  if (status == 200) {
    return data.locations
  } else {
    return { locations: [] }
  }
}


export async function postEveryshop(ref: string) {
  const url = `${host}/api/everyshop?uustr=${ref}`

  const { data } = await axios.post(url, {})
  return data
}

export async function postEveryjob(trackingNo: string) {
  const url = `${host}/api/everyjob?ticker=${trackingNo}`

  const { data } = await axios.post(url, {})
  return data
}

export async function callRoute(routeNo: string) {
  const url = `${host}/api/route/${routeNo}/call`

  const { data } = await axios.post<ResCancelRoute>(url)
  return data
}