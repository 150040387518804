import { combineReducers } from 'redux'
import Order from './Order'
import Route from './Route'
import Todo from './Todo'
// import visibilityFilter from './visibilityFilter'
import { Action, AUTH_PASS, AUTH_FAIL, SHOW_MODAL, HIDE_MODAL, SEARCH, COUNT, FILTER } from '../actions';




export interface ModalState {
  show: boolean,
  index: number,
}

export interface AuthState {
  auth: boolean,
  token: string,
}

export interface SearchState {
  keyword: string,
  date: Date,
  sort: number,
  skip: number,
  count: number
}

export interface FilterState {
  sender: any,
  status: any,
  service: any,
  box: any,
  ordertype: any
}

const Modal = (state: ModalState = { show: false, index: 0 }, action: Action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        show: true,
        index: action.index
      };
    case HIDE_MODAL:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  };
}

const Search = (state: SearchState = { keyword: '', date: new Date(), sort: -1, skip: 0, count: 0 }, action: Action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        keyword: action.keyword,
        date: action.date,
        sort: action.sort,
        skip: action.skip,
        count: action.count
      };
    case COUNT:
      return {
        ...state,
        count: action.count
      };
    default:
      return state;
  };
}

const Filter = (state: FilterState = { sender: [], status: [], service: [], box: [], ordertype: [] }, action: Action) => {
  switch (action.type) {
    case FILTER:
      return {
        ...state,
        sender: action.sender,
        status: action.status,
        service: action.service,
        box: action.box,
        ordertype: action.ordertype
      };
    default:
      return state;
  };
}

const cc_auth = localStorage.getItem('auth')
const cc_token:any = localStorage.getItem('token')


const Authen = (state:AuthState = { auth:cc_auth?true:false, token:cc_auth?cc_token:'' }, action:Action) =>{
  switch (action.type) {
    case AUTH_PASS:
      return {
        ...state,
        auth: true,
        token: action.token
      };
    case AUTH_FAIL:
      return {
        ...state,
        auth: false,
        token: ''
      };
    default:
      return state;
  };
}

export default combineReducers({
  Authen,
  Search,
  Filter,
  Modal,
  Order,
  Route,
  Todo
})
