import { useState, useEffect } from 'react';


export const COLOR_STARTUS = {
	'ASSIGNING_DRIVER': '#444966',
	'ON_GOING': '#7886FF',
	'PICKED_UP': '#FDB513',
	'COMPLETED': '#1ABC9C',
	'CANCELED': '#E54363',
	'ERROR': '#E54363',
	'WAITING': '#8743A7',
	'now': '#8743A7',
	'Verified': '#444966',
	'Picking': '#7886FF',
	'Pickup failed': '#444966',
	'On the way to drop-off': '#444966',
	'Processing at warehouse': '#7886FF',
	'Ready to ship at warehouse': '#7886FF',
	'Out of stock': '#7886FF',
	'Awaiting to sourcing': '#7886FF',
	'Cross border processing': '#FDB513',
	'Received at origin facility': '#FDB513',
	'Departed at origin facility': '#FDB513',
	'Cross border transit': '#FDB513',
	'Customs cleared': '#FDB513',
	'Customs onhold': '#FDB513',
	'Received at desitination facility': '#FDB513',
	'Departed at desitination facility': '#FDB513',
	'Shipped': '#FDB513',
	'Out for delivery': '#FDB513',
	'Out of delivery': '#FDB513',
	'Awaiting to return': '#444966',
	'Return processing': '#E54363',
	'Return approved': '#E54363',
	'Returning': '#E54363',
	'Out of return': '#E54363',
	'Return refused': '#E54363',
	'Awaiting receive at facility': '#444966',
	'Return to orgin facility': '#444966',
	'Returned at orgin facility': '#444966',
	'Returned': '#444966',
	'Cancelled by sellers': '#E54363',
	'Cancelled by operator': '#E54363',
	'Cancelled by partner': '#E54363',
	'Cancelled by system': '#E54363',
	'Pending for audit': '#E54363',
	'Delivered': '#1ABC9C',
	'Destroy parcel': '#1ABC9C'
}


export const THAI_STARTUS = {
	'ASSIGNING_DRIVER': 'ค้นหาคนขับ',
	'ON_GOING': 'กำลังไปรับสินค้า',
	'PICKED_UP': 'กำลังไปส่งสินค้า',
	'COMPLETED': 'จัดส่งสำเร็จ',
	'CANCELED': 'ยกเลิก',
	'ERROR': 'เกิดข้อผิดพลาด',
}

export const ENLISH_STARTUS = {
	'ASSIGNING_DRIVER': 'ASSIGNING',
	'ON_GOING': 'MATCHED',
	'PICKED_UP': 'PICKED UP',
	'COMPLETED': 'COMPLETED',
	'CANCELED': 'CANCELLED',
	'ERROR': 'ERROR',
	'now': 'WAITING',
	'Verified': 'Verified',
	'Picking': 'Picking',
	'Pickup failed': 'Pickup failed',
	'On the way to drop-off': 'On the way to drop-off',
	'Processing at warehouse': 'Processing at warehouse',
	'Ready to ship at warehouse': 'Ready to ship at warehouse',
	'Out of stock': 'Out of stock',
	'Awaiting to sourcing': 'Awaiting to sourcing',
	'Cross border processing': 'Cross border processing',
	'Received at origin facility': 'Received at origin facility',
	'Departed at origin facility': 'Departed at origin facility',
	'Cross border transit': 'Cross border transit',
	'Customs cleared': 'Customs cleared',
	'Customs onhold': 'Customs onhold',
	'Received at desitination facility': 'Received at desitination facility',
	'Departed at desitination facility': 'Departed at desitination facility',
	'Shipped': 'Shipped',
	'Out for delivery': 'Out for delivery',
	'Out of delivery': 'Out of delivery',
	'Awaiting to return': 'Awaiting to return',
	'Return processing': 'Return processing',
	'Return approved': 'Return approved',
	'Returning': 'Returning',
	'Out of return': 'Out of return',
	'Return refused': 'Return refused',
	'Awaiting receive at facility': 'Awaiting receive at facility',
	'Return to orgin facility': 'Return to orgin facility',
	'Returned at orgin facility': 'Returned at orgin facility',
	'Returned': 'Returned',
	'Cancelled by sellers': 'Cancelled by sellers',
	'Cancelled by operator': 'Cancelled by operator',
	'Cancelled by partner': 'Cancelled by partner',
	'Cancelled by system': 'Cancelled by system',
	'Pending for audit': 'Pending for audit',
	'Delivered': 'Delivered',
	'Destroy parcel': 'Destroy parcel'
}

export const COLOR = {
	'YELLOW_1': '#FDB513',
	'BLUE_DARK': '#444966',
	'Y_BRIGHT': '#FAF1C4',
	'DARK_1': '#666666',
	'DARK_2': '#242D31',
	'WHITE_1': '#F2F2F2',
	'GRAY': '#E5E5E5',
	'WHITE': '#FFFFFF',
	'BLUE_DARK_OPA_1': 'rgba(68,73,102,0.1)',
	'PLACEHOLDER': '#C4C4C4',
}




function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}
export const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export const loadMarkers = (route: any) => {
	let renderedMarkers: any = []
	for (let index = 0; index < route.length; index++) {
		const element: any = route[index];
		if (index === 0) {
			renderedMarkers.push({ text: 's', no: 'shop', lat: element.shop.location.lat, lng: element.shop.location.lng })
		}
		renderedMarkers.push({ text: 'c', no: element.no, lat: element.location.coordinates[1], lng: element.location.coordinates[0] })
	}
	return renderedMarkers;
}


export const loadMarkerswiothTracking = (locations: any) => {
	let renderedMarkers: any = []
	for (let index = 0; index < locations.length; index++) {
		const element: any = locations[index];
		renderedMarkers.push({ text: 'd', lat: element.location.lat, lng: element.location.lng })
	}
	return renderedMarkers;
}

export const convertDatetiTimestamp = (date: Date | string | number): number => {
	const dateObj = new Date(date);
	return (dateObj.getTime() / 1000)
}