


import { css } from 'emotion';
import React from 'react';
import ReactDOM from "react-dom";
import { useSelector } from 'react-redux'
// import { hideModal } from '../actions';
// import ReactModal from "react-modal";
// import { useModal } from "react-modal-hook";


// interface ReactPortal extends ReactElement<any> {
//     key: Key | null;
//     children: ReactNode;
// }
const Modal = ({ children }: { children: JSX.Element }) => {
  const show = useSelector((state: any) => state.Modal.show)
  if (show) {
    return (
      ReactDOM.createPortal(
        <div className={styles.modal}>
          {children}
        </div>,
        document.body
      )
    )
  }
  else {
    return null
  }



}

const styles = {
  modal: css`
    position: fixed;
    height: 100%;
    width: 600px;
    top: 0px;
    right: 0px;
    background: #fff;
    z-index:9999999;
    border-radius: 23px 0px 0px 23px;
  `,
  button: css`
    position: absolute;
    bottom: 0px;
  `,

};

export default Modal;