


import { css } from 'emotion';
import React from 'react';
import { COLOR } from '../const'
const Menu = ({ uri, text }: { uri: string, text: string }) => {
  const current_uri = window.location.pathname
  return (
    <li>
      <a className={current_uri === uri ? styles.active : ""} href={uri}>{text}</a>
    </li>
  )
}


const Header = () => {
  return (
    <div className={styles.root}>
      <ul className={styles.menu}>
        <Menu uri={"/order"} text={"List Order"}></Menu>
        <Menu uri={"/route"} text={"List Route"}></Menu>
        <Menu uri={"/parcel"} text={"List Parcel"}></Menu>
      </ul>
    </div>
  )


}

const styles = {
  root: css`
  position: fixed;
  left: 50%;
  top:0;
  transform: translate(-50%, 0);
  z-index: 9999;
  `,
  menu: css`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  width: 560px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13);
  border-radius: 0px 0px 13px 13px;
  li {
    float: left;
    width: 186px;
    &:nth-child(2){
      border-radius: 0px 0px 13px 0px;
    }
    &:nth-child(1){
      border-radius: 0px 0px 0px 13px;
    }
  }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 5px 16px;
    font-size: 22px;
    text-decoration: none;
    color: ${COLOR['BLUE_DARK']};
    border-bottom: 2px solid transparent;
    // font-family: system-ui;
  }
  
  li a:hover {
    background-color: ${COLOR['BLUE_DARK_OPA_1']};
  }

  `,
  active: css`
  color: ${COLOR['YELLOW_1']} !important;
  font-weight: bold;
  border-bottom: 2px solid ${COLOR['YELLOW_1']}!important;
  `,
  button: css`
    position: absolute;
    bottom: 0px;
  `,

};


export default Header;


