
import { Action, ADD_ROUTE, LOAD_LIST_ROUTE, CREATE_ROUTE } from '../actions';




export interface IState {
  routes: object[],
  createroute: object
}

// interface ServerResponse {
//   data:  object[]
// }

const RouteReducer = (state: IState = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ADD_ROUTE:
      return {
        ...state,
        routes: [
          ...state.routes,
          {
            route: action.route
          }
        ]
      };
    case LOAD_LIST_ROUTE:
      return {
        ...state,
        routes: action.routes
      };
    case CREATE_ROUTE:
      return {
        ...state,
        createroute: action.route
      };
    default:
      return state;
  };
}




export const INITIAL_STATE: IState = {
  "routes": [
  ],
  "createroute": {
    "orders":[]
  }

};

export default RouteReducer;